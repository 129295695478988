

.#{$header-name} {
  position: fixed;
  width: 100%;
  background: #000;
  color: #fff;
  padding: 4px 0px;
  z-index: 3;
  top: 0;



  &__logo-deloitte {
    display: inline-block;
    width: 68px;
    height: 14px;



    img {
      vertical-align: top;
    }
  }



  &__brand {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 32px 2px 0;
    padding: 0;
    color: #fff !important;
    white-space: nowrap;
    text-decoration: none;



    &:hover {
      text-decoration: none;
    }
  }
}







/* Header NOrmal styles */

.deloite-logo {
  padding-right: 8px;
  margin-top: 8px;
  width: 154px;
  float: left;
}



.deloite-logo span {
  color: #afb3b7;
  font-size: 15px;
  font-weight: 200;
  margin-top: -2px;
  display: inline-block;
  margin-left: 15px;
}

.application-logo {
  margin-right: 15px;
  margin-top: 7px;
  float: left;
  }

  .navbar-header {
  }

  .navbar-header .nav > li > a:focus, .navbar-header .nav > li > a:hover {
    color: #fff;
    background-color: transparent;
  }



  .navbar-header .navbar-nav > .active > a,
  .navbar-header .navbar-nav > .active > a:focus,
  .navbar-header .navbar-nav > .active > a:hover {
    color: #fff;
    background-color: #26890D;
  }

  .navbar-header .navbar-nav > .open > a,
  .navbar-header .navbar-nav > .open > a:focus,
  .navbar-header .navbar-nav > .open > a:hover {
    color: #26890D;
    background-color: transparent;
    font-weight: 700 !important;
  }

  .top_nav {
    /*display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: static;*/
    position: relative;
  }



  .top_nav a {
    //styleName: body.text.m.l [14px Regular];
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #97999B;
  }

  .top_nav .nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }





  .top_nav .btn.focus, .top_nav .btn:focus, .top_nav .btn:hover {
    color: #FFF !important;
  }



.top_nav .drop_1 .btn.active, .top_nav .drop_1 .btn:active, .top_nav .drop_1 .btn:hover {
  color: #26890D !important;
}

.top_nav .drop_1 .btn.focus, .top_nav .drop_1 .btn:focus {
  color: #26890D !important;
}

.btn_nobg {
  background: none !important;
  color: #97999B !important;
}

btn:focus



.drop_1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 16px;
  position: static;
  width: 214px;
  height: 40px;
  left: 131px;
  top: 0px;
}



.drop_top {
  position: static;
  width: 20px;
  height: 40px;
  right: 16px;
  top: 19px;
}



.drop_1 .dropdown-menu {
  border-radius: 0px !important;
  background: #000;
}



.drop_1 .dropdown-menu > li > a {
  color: #FFF !important;
  line-height: 25px;
}



.drop_1 .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  background-color: #000 !important;
  outline: none !important;
}

/*main home body container  */





.header-left-col {
  float: left;
}



.profile_col {
 
  position: absolute;
  width: 40px;
  height: 40px;
  float: right;
  right: 0;
  top: -50px;
}



.Userpick {
  position: static;
  width: 40px;
  height: 40px;
  /* left: 0px;

  top: 0px;*/
  /* Inside Auto Layout */
  /*flex: none;

  order: 1;

  flex-grow: 0;*/
  /* margin: 32px 0px;*/
}



.Userpick_img {
  text-align: center;
  font-size: 20px;
  margin-top: 9px;
}

.header_second .right {
  display: block;
  float: none;
}
.header_second h1 {
  padding-bottom: 14px;
}
/* Mobile Tab Responsive */
@media (min-width: 768px) {
  .profile_col {
    top: 0;
  }

  .header_second .right {
    float: right;
  }
}
  .navbar-toggle {
    float: left !important;
    border: 1px solid #FFF;
    border-radius: 50%;
    padding: 9px 6px;
  }

  .navbar-toggle .icon-bar {
    background: #FFF;
  }
   

