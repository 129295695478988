






.dds-breadcrumbs {
  font: 400 14px/20px "Open Sans",sans-serif;
  display: inline-flex;
  -ms-flex-align: center;
  width: 100%;
}





.dds-breadcrumbs__link {
  display: inline-flex;
  -ms-flex-align: center;
  flex-shrink: 0;
}



.dds-breadcrumbs__link .dds-link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



.dds-breadcrumbs__link a {
  color: #000;
  font-size: 12px;
  font-family: 'Open Sans';
}

.dds-link:visited {
  color: #012169;
}



.dds-breadcrumbs__link .divider {
  font: 400 14px/20px "Open Sans",sans-serif;
  margin: 0 8px;
  color: #53565A;
}

.ggr {
  color: #0F0;
}
