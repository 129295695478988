/*spinner styles */

/*Main Spinner*/

@-webkit-keyframes uil-default-anim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes uil-default-anim {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.uil-default-css > div:nth-of-type(1) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(2) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.4722222222222222s;
  animation-delay: -0.4722222222222222s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(3) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.4444444444444444s;
  animation-delay: -0.4444444444444444s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(4) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.4166666666666667s;
  animation-delay: -0.4166666666666667s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(5) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.3888888888888889s;
  animation-delay: -0.3888888888888889s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(6) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.3611111111111111s;
  animation-delay: -0.3611111111111111s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(7) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.33333333333333337s;
  animation-delay: -0.33333333333333337s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(8) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.3055555555555556s;
  animation-delay: -0.3055555555555556s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(9) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.2777777777777778s;
  animation-delay: -0.2777777777777778s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(10) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(11) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.2222222222222222s;
  animation-delay: -0.2222222222222222s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(12) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.19444444444444442s;
  animation-delay: -0.19444444444444442s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(13) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.16666666666666669s;
  animation-delay: -0.16666666666666669s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(14) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.1388888888888889s;
  animation-delay: -0.1388888888888889s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(15) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.1111111111111111s;
  animation-delay: -0.1111111111111111s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(16) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.08333333333333331s;
  animation-delay: -0.08333333333333331s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(17) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.05555555555555558s;
  animation-delay: -0.05555555555555558s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(18) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.02777777777777779s;
  animation-delay: -0.02777777777777779s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(19) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(20) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.02777777777777779s;
  animation-delay: 0.02777777777777779s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(21) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.05555555555555558s;
  animation-delay: 0.05555555555555558s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(22) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.08333333333333337s;
  animation-delay: 0.08333333333333337s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(23) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.11111111111111116s;
  animation-delay: 0.11111111111111116s;
}



.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(24) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.13888888888888884s;
  animation-delay: 0.13888888888888884s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(25) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.16666666666666663s;
  animation-delay: 0.16666666666666663s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(26) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.19444444444444442s;
  animation-delay: 0.19444444444444442s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(27) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.2222222222222222s;
  animation-delay: 0.2222222222222222s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(28) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(29) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.2777777777777778s;
  animation-delay: 0.2777777777777778s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(30) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.3055555555555556s;
  animation-delay: 0.3055555555555556s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(31) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.33333333333333337s;
  animation-delay: 0.33333333333333337s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(32) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.36111111111111116s;
  animation-delay: 0.36111111111111116s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(33) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.38888888888888884s;
  animation-delay: 0.38888888888888884s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(34) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.41666666666666663s;
  animation-delay: 0.41666666666666663s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(35) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.4444444444444444s;
  animation-delay: 0.4444444444444444s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(36) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.4722222222222222s;
  animation-delay: 0.4722222222222222s;
}

.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e7e7e7;
  opacity: 0.5;
}

.mainspinner {
  margin-top: 10%;
  margin-left: 45%;
}

.mainspinner .txtbold {
  min-width: 100px;
}

.mainspinner .col-lg-offset-6 {
  margin-left: 30%;
}
/* Start :: Inline Spinner - Styles */
#circularG {
  position: relative;
  width: 34px;
  height: 34px;
  margin: auto;
  display: inline-block;
}

.circularG {
  position: absolute;
  background-color: rgb(134,188,37);
  width: 8px;
  height: 8px;
  border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  animation-name: bounce_circularG;
  -o-animation-name: bounce_circularG;
  -ms-animation-name: bounce_circularG;
  -webkit-animation-name: bounce_circularG;
  -moz-animation-name: bounce_circularG;
  animation-duration: 1.1s;
  -o-animation-duration: 1.1s;
  -ms-animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  -moz-animation-duration: 1.1s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#circularG_1 {
  left: 0;
  top: 14px;
  animation-delay: 0.41s;
  -o-animation-delay: 0.41s;
  -ms-animation-delay: 0.41s;
  -webkit-animation-delay: 0.41s;
  -moz-animation-delay: 0.41s;
}

#circularG_2 {
  left: 4px;
  top: 4px;
  animation-delay: 0.55s;
  -o-animation-delay: 0.55s;
  -ms-animation-delay: 0.55s;
  -webkit-animation-delay: 0.55s;
  -moz-animation-delay: 0.55s;
}

#circularG_3 {
  top: 0;
  left: 14px;
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}

#circularG_4 {
  right: 4px;
  top: 4px;
  animation-delay: 0.83s;
  -o-animation-delay: 0.83s;
  -ms-animation-delay: 0.83s;
  -webkit-animation-delay: 0.83s;
  -moz-animation-delay: 0.83s;
}

#circularG_5 {
  right: 0;
  top: 14px;
  animation-delay: 0.97s;
  -o-animation-delay: 0.97s;
  -ms-animation-delay: 0.97s;
  -webkit-animation-delay: 0.97s;
  -moz-animation-delay: 0.97s;
}

#circularG_6 {
  right: 4px;
  bottom: 4px;
  animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}

#circularG_7 {
  left: 14px;
  bottom: 0;
  animation-delay: 1.24s;
  -o-animation-delay: 1.24s;
  -ms-animation-delay: 1.24s;
  -webkit-animation-delay: 1.24s;
  -moz-animation-delay: 1.24s;
}

#circularG_8 {
  left: 4px;
  bottom: 4px;
  animation-delay: 1.38s;
  -o-animation-delay: 1.38s;
  -ms-animation-delay: 1.38s;
  -webkit-animation-delay: 1.38s;
  -moz-animation-delay: 1.38s;
}



@keyframes bounce_circularG {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.3);
  }
}

@-o-keyframes bounce_circularG {
  0% {
    -o-transform: scale(1);
  }

  100% {
    -o-transform: scale(.3);
  }
}

@-ms-keyframes bounce_circularG {
  0% {
    -ms-transform: scale(1);
  }

  100% {
    -ms-transform: scale(.3);
  }
}

@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1);
  }

  100% {
    -webkit-transform: scale(.3);
  }
}

@-moz-keyframes bounce_circularG {
  0% {
    -moz-transform: scale(1);
  }

  100% {
    -moz-transform: scale(.3);
  }
}
/* End :: Inline Spinner - Styles */
