.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  height: 35px;
  z-index: 2;
}

.coockiePopup {
  display: block !important;
}
