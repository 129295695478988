
body {
  color: #000;
}



body, input, textarea, select, button {
  font-family: 'Open Sans', sans-serif !important;
}





body {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}





a {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}





.white {
  color: #FFF !important;
}

.black {
  color: #000 !important;
}
.red {
    color: #F00 !important;
}
.green {
  color: #26890D;
}
.cool_gray2 {
  color: #D0D0CE;
}

.cool_gray11 {
  color: #53565A;
}

.cool_gray9 {
  color: #75787B;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
}







h1 {
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}



h2 {
  font-size: 16px;
  line-height: 24px;
}



h3 {
  font-size: 14px;
  line-height: 24px;
}



h4 {
  font-size: 13px;
  line-height: 20px;
}







mark,
.mark {
  background: #e0eeff;



  &:hover {
    background: #b7d7ff;
  }
}



.weight {

  &-600 {
    font-weight: 600;
  }



  &-normal {
    font-weight: normal;
  }
}



.align {

  &-left {
    text-align: left;
  }



  &-right {
    text-align: right;
  }



  &-center {
    text-align: center;
  }
}



.color {

  &-link {
    color: #00a3e0;
  }



  &-danger {
    color: #da291c;
  }



  &-warning {
    color: #f39200;
  }



  &-success {
    color: #86bc25;
  }



  &-black {
    color: #000;
  }



  &-secondary {
    color: #97999b;
  }
}

/*# sourceURL=typography.scss */

.heading_h1 {
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}
