@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
.header {
  position: fixed;
  width: 100%;
  background: #000;
  color: #fff;
  padding: 4px 0px;
  z-index: 3;
  top: 0;
}
.header__logo-deloitte {
  display: inline-block;
  width: 68px;
  height: 14px;
}
.header__logo-deloitte img {
  vertical-align: top;
}
.header__brand {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 32px 2px 0;
  padding: 0;
  color: #fff !important;
  white-space: nowrap;
  text-decoration: none;
}
.header__brand:hover {
  text-decoration: none;
}

/* Header NOrmal styles */
.deloite-logo {
  padding-right: 8px;
  margin-top: 8px;
  width: 154px;
  float: left;
}

.deloite-logo span {
  color: #afb3b7;
  font-size: 15px;
  font-weight: 200;
  margin-top: -2px;
  display: inline-block;
  margin-left: 15px;
}

.application-logo {
  margin-right: 15px;
  margin-top: 7px;
  float: left;
}

.navbar-header .nav > li > a:focus, .navbar-header .nav > li > a:hover {
  color: #fff;
  background-color: transparent;
}

.navbar-header .navbar-nav > .active > a,
.navbar-header .navbar-nav > .active > a:focus,
.navbar-header .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #26890D;
}

.navbar-header .navbar-nav > .open > a,
.navbar-header .navbar-nav > .open > a:focus,
.navbar-header .navbar-nav > .open > a:hover {
  color: #26890D;
  background-color: transparent;
  font-weight: 700 !important;
}

.top_nav {
  /*display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: static;*/
  position: relative;
}

.top_nav a {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #97999B;
}

.top_nav .nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}

.top_nav .btn.focus, .top_nav .btn:focus, .top_nav .btn:hover {
  color: #FFF !important;
}

.top_nav .drop_1 .btn.active, .top_nav .drop_1 .btn:active, .top_nav .drop_1 .btn:hover {
  color: #26890D !important;
}

.top_nav .drop_1 .btn.focus, .top_nav .drop_1 .btn:focus {
  color: #26890D !important;
}

.btn_nobg {
  background: none !important;
  color: #97999B !important;
}

btn:focus .drop_1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 16px;
  position: static;
  width: 214px;
  height: 40px;
  left: 131px;
  top: 0px;
}

.drop_top {
  position: static;
  width: 20px;
  height: 40px;
  right: 16px;
  top: 19px;
}

.drop_1 .dropdown-menu {
  border-radius: 0px !important;
  background: #000;
}

.drop_1 .dropdown-menu > li > a {
  color: #FFF !important;
  line-height: 25px;
}

.drop_1 .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
  background-color: #000 !important;
  outline: none !important;
}

/*main home body container  */
.header-left-col {
  float: left;
}

.profile_col {
  position: absolute;
  width: 40px;
  height: 40px;
  float: right;
  right: 0;
  top: -50px;
}

.Userpick {
  position: static;
  width: 40px;
  height: 40px;
  /* left: 0px;

  top: 0px;*/
  /* Inside Auto Layout */
  /*flex: none;

  order: 1;

  flex-grow: 0;*/
  /* margin: 32px 0px;*/
}

.Userpick_img {
  text-align: center;
  font-size: 20px;
  margin-top: 9px;
}

.header_second .right {
  display: block;
  float: none;
}

.header_second h1 {
  padding-bottom: 14px;
}

/* Mobile Tab Responsive */
@media (min-width: 768px) {
  .profile_col {
    top: 0;
  }

  .header_second .right {
    float: right;
  }
}
.navbar-toggle {
  float: left !important;
  border: 1px solid #FFF;
  border-radius: 50%;
  padding: 9px 6px;
}

.navbar-toggle .icon-bar {
  background: #FFF;
}

body {
  background-color: #FFF;
}

.main_container {
  padding-top: 70px;
}

a {
  color: #007CB0;
}

a:focus {
  outline: none;
}

.font14-regular {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #007CB0;
}

.font14-black {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.font12-regular {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

/* Alignments */
.right {
  float: right;
}

.left {
  float: left;
}

.align_center {
  text-align: center;
}

/*margins and Paddings*/
.margin_0 {
  margin: 0px;
}

.padding_0 {
  padding: 0px;
}

.paddinleft28 {
  padding-left: 28px !important;
}

.paddingbottom-10 {
  padding-bottom: 10px;
}

.paddingbottom-20px {
  padding-bottom: 20px;
}

.margintop-10 {
  margin-top: 10px;
}

.margintop-20 {
  margin-top: 20px;
}

.margintop-30 {
  margin-top: 30px;
}

.marginbottom-0 {
  margin-bottom: 0px;
}

.marginbottom-20 {
  margin-bottom: 20px;
}

.margin-top-bottom-checkbox {
  margin-top: 17px !important;
  margin-bottom: 0px !important;
}

.marginright15 {
  margin-right: 10px;
}

.margin_right15 {
  margin-right: 15px;
}

.paddingall10 {
  padding: 10px;
}

.padding_right-0 {
  padding-right: 0px;
}

.padding_left-0 {
  padding-left: 0px;
}

.width_full {
  width: 100%;
}

.cpointer {
  cursor: pointer;
}

/*tables styles */
.table-responsive {
  overflow-y: hidden;
}

/*positions */
.relative_p {
  position: relative;
}

.icon_pos span {
  position: relative;
  top: 3px;
}

/*icons styles*/
.grid_list .search_ico {
  top: 5px;
}

.check_icon {
  padding: 2px;
  border-radius: 50%;
  color: white !important;
  background-color: dodgerblue;
  margin-left: 5px;
}

.dds-icon_search,
.dds-icon_delete,
.dds-icon_delete,
.dds-icon_eyeopen,
.dds-icon_arrow-up,
.dds-icon_sort-down,
.dds-icon_plus,
.dds-icon_calendar,
.dds-icon_timer,
.dds-icon_hourglass,
.dds-icon_check,
.dds-icon_dots-v,
.dds-icon_edit-24,
.dds-dds-icon_arrow-long-up,
.dds-icon_arrow-long-down,
.dds-icon_info,
.dds-icon_arrow-round-right {
  font-size: 16px;
}

.search_ico {
  position: absolute;
  top: 10px;
  left: 8px;
}

.calendar_ico {
  position: absolute;
  top: 5px;
  right: 5px;
}

.info_ico {
  position: relative;
  top: 2px;
  border: 1px solid #000;
  border-radius: 100%;
}

.dds-icon_search,
.dds-icon_delete {
  color: #D0D0CE;
}

.dds-icon_delete,
.dds-icon_eyeopen,
.dds-icon_arrow-up,
.dds-icon_arrow-down .dds-icon_sort-down,
.dds-icon_dots-v,
.dds-icon_edit-24,
.dds-icon_timer,
.dds-icon_check,
.dds-icon_hourglass,
.dds-icon_cancel,
.dds-icon_info {
  color: #000;
}

.dds-icon_delete,
.dds-icon_arrow-round-right {
  cursor: pointer;
}

.dds-icon_plus {
  color: #26890D;
  position: relative;
  top: 2px;
  right: -3px;
}

.dds-icon_arrow-up,
.dds-icon_arrow-down {
  color: #000;
  float: right;
  cursor: pointer;
}

.dds-icon_calendar {
  color: #75787B;
}

.sort-down_ico {
  position: relative;
  top: 0px;
  padding-left: 3px;
}

.dds-icon_timer {
  color: #75787B;
}

.over_due {
  color: #F00;
}

.full_width {
  width: 100%;
  float: left;
}

.dashboard_list .dds-icon_check {
  color: #FFF;
  background: #26890D;
  font-size: 17px !important;
}

.dashboard_list .dds-icon_hourglass {
  background: #F00;
  color: #FFF;
  font-size: 17px !important;
}

.dashboard_list .dds-icon_timer {
  background: #ffe100;
  color: #FFF;
  font-size: 17px !important;
}

.dashboard_list .dds-icon_cancel {
  background: #ff8300;
  color: #FFF;
  font-size: 17px !important;
}

.mat-datepicker-toggle .mat-button-wrapper {
  display: none !important;
}

/*buttons styles*/
/*buttos */
.nav_btns_green {
  padding: 9px 16px;
  background-color: #26890D;
  border-radius: 0px !important;
  color: #FFF !important;
}

.green_btn {
  background: #26890D;
  border-radius: 0px;
  border: 0;
}

.addnew_btn {
  background: #FFF;
  border: 1px solid #26890D;
  color: #26890D;
}

.additem {
  color: #26890D;
  border: none;
  font-weight: 700;
  font-size: 14px;
  background: none;
  padding-left: 0px;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none !important;
  outline-offset: -2px;
}

.actions .btn-none {
  background: none !important;
  border: none !important;
}

.actions .dropdown-menu > li > a {
  padding: 7px 10px;
  text-decoration: none;
  cursor: pointer;
}

.actions .btn.active, .actions .btn:active {
  box-shadow: none;
}

.btn.active, .btn:active {
  box-shadow: none !important;
}

.item_addbtn {
  position: relative;
  right: 0;
  float: right;
}

/*sub header*/
.btn {
  border-radius: 0px;
}

.btns_download {
  padding: 5px 12px;
  border: 1px solid #000 !important;
  background: #FFF !important;
  border-radius: 0px !important;
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans";
  color: #000;
}

.btns_download:hover {
  color: #000;
}

.disable_btn {
  background: #BBBCBC;
  color: #FFF;
  cursor: default;
}

.disable_btn:hover {
  color: #FFF;
}

.disable_input {
  color: #FFF;
  cursor: default;
}

.line_height28 {
  line-height: 28px !important;
}

/*form controls styles*/
.form-control {
  border-radius: 0px !important;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  box-shadow: none !important;
  border: 1px solid #D0D0CE !important;
}

label {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

textarea {
  resize: none !important;
}

textarea:focus {
  outline: none !important;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  color: #828282;
}

.form-control[disabled], fieldset[disabled] .form-control, .disable {
  color: #828282;
}

fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] {
  cursor: default;
}

[type=checkbox]:disabled:not(:checked) + .checkmark,
[type=checkbox]:disabled:checked + .checkmark {
  box-shadow: none;
  border-color: #bbb;
  background-color: #e9e9e9;
  cursor: default;
}

[type=radio]:disabled:not(:checked) + .checkmark,
[type=radio]:disabled:checked + .checkmark {
  box-shadow: none;
  border-color: #8a8a8a;
  background-color: #bdb8b8;
  cursor: default;
}

.mat-select-panel {
  min-width: calc(100% + 1px) !important;
}

/*fancy check box and radio buttos*/
.roles_list {
  width: 100%;
  float: left;
}

.roles_list ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.roles_list ul li {
  padding-left: 15px;
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.txt_check {
  position: relative;
  top: -6px;
}

.sub_list {
  margin-top: 15px;
  margin-bottom: 15px;
}

.controles_list {
  margin-bottom: 5px;
}

/*fancy check box */
.container-cr {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_txt {
  opacity: 0;
  margin-left: -32px;
}

/* Hide the browser's default checkbox */
.container-cr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 13px;
  width: 13px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #000;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
.container-cr input:checked ~ .checkmark {
  background-color: #26890D;
  border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-cr input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-cr .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* end fancy check box styles*/
/*Fancy radio button styles*/
.container-rad {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-rad input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-rad .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
/* When the radio button is checked, add a blue background */
.container-rad input:checked ~ .checkmark {
  background-color: #26890D;
  border: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-rad .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-rad input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-rad .checkmark:after {
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

/*Fancy radion button end */
.roles_list .nav-pills {
  width: 100%;
  float: left;
  background: #FFFFFF;
  border: 1px solid #D0D0CE;
  box-sizing: border-box;
}

.roles_list .nav-pills > li > a {
  color: #000;
  float: left;
}

.radio_btns .form-check-input {
  margin-right: 10px;
}

.radio_btns label {
  font-weight: normal;
}

/**/
.divide-line {
  height: 8px;
  width: 1px;
  top: 10px;
  border-radius: 0px;
  position: relative;
  color: #D0D0CE;
}

.actions_drop {
  min-width: 127px !important;
  Padding: 8px 0px 8px 0px;
}

.actions .dropdown-menu > li > a:focus, .actions .dropdown-menu > li > a:hover {
  color: #000;
  background: none !important;
  outline: none !important;
}

.grid_list .dropdown-menu {
  left: auto;
  right: 0px !important;
}

/*drop down actions*/
/*Grid styles */
.pro_grid .table-responsive {
  overflow: auto;
}

.table-responsive .table > thead > tr > th {
  border-bottom: 1px solid #E5E5E5;
}

.grid_list .form-control {
  border-radius: 0px !important;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #D0D0CE !important;
  height: 24px;
  padding: 3px 12px;
  box-shadow: none !important;
}

.grid_list a {
  color: #007CB0;
  text-decoration: underline;
}

.grid_list .checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}

.d_col-1 {
  width: 63px;
}

.d_col-2 {
  width: 373px;
}

.d_col-3 {
  width: 169px;
}

.d_col-4 {
  width: 122px;
}

.d_col-5 {
  width: 161px;
}

.d_col-6 {
  width: 155px;
}

.d_col-7 {
  width: 373px;
}

.userlist_col-1 {
  width: 48px;
}

.userlist_col-2 {
  width: 214px;
}

.userlist_col-3 {
  width: 639px;
}

.userlist_col-4 {
  width: 85px;
}

.user_box .form-control {
  font-size: 14px;
  color: #000;
}

.close_btn {
  font-weight: bold;
  color: #000;
  cursor: pointer;
  padding: 10px;
  margin-top: -40px;
}

.word_s {
  word-spacing: normal;
}

.assets_grid {
  overflow-y: inherit !important;
  overflow-x: inherit !important;
}

/*mat grid */
.mat-elevation-z8 {
  box-shadow: none !important;
}

.mat-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  font-family: Open Sans;
}

.mat-table > tbody > tr:nth-of-type(odd) {
  background: #FAFAFA;
}

th.mat-header-cell {
  border-top: 0 !important;
}

td.mat-cell a {
  cursor: pointer !important;
}

tr.mat-header-row,
tr.mat-row, tr.mat-footer-row {
  height: auto !important;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 10px 8px !important;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  border-bottom-width: unset;
  border-bottom-style: none;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 8px !important;
}

.mat-sort-header-content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #53565A;
  margin-bottom: 3px;
}

.mat-cell, .mat-footer-cell {
  color: black;
  font-family: Open Sans;
  font-weight: normal;
  font-size: 14px;
}

th.mat-header-cell {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 14px;
  color: #53565A;
}

.mat-sort-header-arrow {
  display: none !important;
}

.mat-sort-header-content {
  white-space: pre;
}

.mat-column-Invoice-Date,
.mat-column-Payroll-Period-End-Date,
.mat-column-Receipt-Date,
.mat-column-Transaction-Date,
.mat-column-Corp-Card-Billing-Date {
  min-width: 120px;
}

tr.mat-row:last-child td.mat-cell {
  border-bottom: none !important;
}

.mat-column-vendorName {
  width: 130px;
}

.mat-column-deviceType {
  width: 150px;
}

.example-chip-list {
  width: 100%;
}

.mat-chip-remove {
  font-family: "Material Icons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  margin-top: -4px;
}

.close_tag {
  background-color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
  margin-right: 0;
  width: 18px;
  right: 5px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  z-index: 6000;
  font-family: Open Sans;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
  line-height: 18px;
}

.mat-form-field.mat-focused .mat-form-field-label {
  display: none !important;
}

.tags_list .mat-form-field-label-wrapper {
  display: none;
}

.tags_list .mat-form-field-hide-placeholder .mat-input-element::-webkit-input-placeholder {
  color: #999 !important;
  -webkit-text-fill-color: #999;
  transition: inherit;
}

.multi_list {
  width: 200px;
}

.multi_user {
  display: block;
  margin: 7px 0;
}

.multi_user1 {
  float: left;
  margin: 10px;
}

.tootltip_txt {
  white-space: nowrap;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

/*pagination styles*/
.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
  line-height: 1em !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.mat-form-field-infix {
  box-shadow: none !important;
  border: 1px solid #D0D0CE !important;
}

.mat-select-value {
  text-align: center;
}

.mat-select-panel {
  box-shadow: none !important;
  border-radius: 0px !important;
}

/*Bread scrum */
.breadscrum_col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.breadscrum_col ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.breadscrum_col ul li {
  float: left;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #000;
}

.breadscrum_col ul li:last-child {
  line-height: 20px;
}

.breadscrum_col .glyphicon-menu-right {
  top: 3px;
}

/*footer paganation*/
.footer_page {
  text-align: right;
}

.footer_page .pagination > li > a,
.footer_page .pagination > li > span {
  border: none !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #000;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #000;
  color: #FFF !important;
}

/* model popup styles */
.mat-dialog-container {
  box-shadow: none;
  background: none;
  right: 0;
  position: fixed;
  z-index: 100000;
  width: 500px !important;
  top: 0px;
  padding: 0px !important;
}

.mat-dialog-actions {
  display: none !important;
}

.overlay {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  top: 48px;
  /* Black #000000 */
  background: #000000;
  opacity: 0.32;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.popup_container {
  position: absolute;
  width: 100%;
  right: 0;
  overflow-y: scroll;
  background: #FFF;
  padding: 20px;
  top: 48px;
}

.popup_container h1 {
  width: 333px;
  float: left;
}

.popup_container form {
  position: relative;
  top: -20px;
}

.popup_container .close_btn {
  margin-top: 0px;
}

.popup_bottom_btn {
  float: right;
  bottom: 20px;
  right: 20px;
}

/*people picker drop down */
/*.cdk-overlay-container {
  width: 350px;
  background: #FFF;*/
/* float: right; */
/*margin-top: 84px;
  margin-right: 59px;
  position: absolute;
  right: -6px;

}*/
.mat-autocomplete-panel {
  max-height: 230px !important;
}

.mat-option {
  height: auto !important;
  border-bottom-style: none !important;
  border: 1px solid #D0D0CE;
  padding: 10px 16px !important;
}

.mat-option:hover,
.mat-option:hover .mat-option-text {
  background: #26890D !important;
  color: #FFF;
}

.mat-option:hover .name_txt {
  color: #FFF !important;
}

.mat-option-text {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.mail_txt {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.mail_txt2 {
  width: 250px;
  float: left;
}

/*Attestation form tab nav */
.nav-tabs > li > a {
  color: #000;
  border: 0px solid transparent;
  border-radius: 0px;
}

.nav > li > a:focus,
.nav > li > a:hover {
  background: none;
  border-radius: 0px;
  border: 0px;
}

.attestation-form .nav-tabs > li.active > a,
.attestation-form .nav-tabs > li.active > a:focus,
.attestation-form .nav-tabs > li.active > a:hover {
  color: #000;
  font-weight: 700 !important;
  font-size: 14px;
  border: 0px;
  border-bottom: 2px solid #26890D;
}

.testuser {
  display: none;
}

.sublist_row {
  margin-bottom: 10px;
}

.pro_grid .nav-tabs > li > a {
  cursor: pointer;
}

.pro_grid .nav-tabs > li.active > a,
.pro_grid .nav-tabs > li.active > a:focus,
.pro_grid .nav-tabs > li.active > a:hover {
  color: #000;
  font-weight: 700 !important;
  font-size: 14px;
  border: 0px;
  border-bottom: 2px solid #26890D;
}

.mat-paginator-range-label {
  margin: 0 3px 0 24px !important;
}

.uploads_f {
  width: 100%;
  margin: 0 auto;
}

.uploads_f .right {
  float: left !important;
}

.uploads_f .align_r {
  float: right;
  width: 100%;
  text-align: left;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.fixed-header {
  position: fixed;
  width: 100%;
  top: 48px;
  background: #FFF;
  padding-top: 22px;
  z-index: 2;
  min-height: 140px;
}

.mat_fixed_header th.mat-header-cell {
  top: 185px !important;
  padding-top: 30px !important;
  z-index: 6666 !important;
  white-space: nowrap;
}

.mat-sort-header-content span {
  cursor: pointer !important;
}

.entity_row label {
  position: relative;
}

.entity_row select {
  border-radius: 0px;
  padding: 2px 5px;
}

.entity_row select:focus-visible {
  border-radius: 0px;
}

.mat-paginator-container {
  justify-content: left !important;
  padding-left: 0px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.template_row label {
  text-align: right;
}

.mangecontrolpage .mat-dialog-container {
  right: 35%;
}

.mangecontrolpage .popup_container {
  height: auto;
  overflow-y: hidden;
  top: 25%;
}

.mangecontrol_row {
  text-align: left;
}

/*.manage_controls th{
    white-space: nowrap;
}*/
/*Tab and Mobile Responsive styles*/
@media (max-width: 768px) {
  .mat-dialog-container {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .popup_container {
    width: 424px;
    height: 90%;
  }

  .mat-paginator-container {
    width: 33% !important;
    justify-content: left !important;
    padding-left: 0px !important;
  }

  .mat-paginator-range-label {
    margin: 0 18px 0 18px !important;
  }

  .mat-paginator-range-actions {
    margin-top: -10px;
  }

  .uploads_f {
    width: 500px;
    margin: 0 auto;
  }

  .uploads_f .align_r {
    float: right;
    width: 100%;
    text-align: right;
  }
}
.center-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 1024px) {
  .testuser {
    display: block;
  }
}
.updown-sort {
  position: relative;
  top: 30px;
  padding-left: 7px;
  cursor: pointer;
}

.sort_disabled {
  cursor: none;
  color: #b1b1b1;
}

.uploads_r {
  width: 100%;
  margin: 0 auto;
}

.disable_reprocess_icon {
  background: #BBBCBC;
  color: #FFF;
  cursor: default;
  pointer-events: none !important;
}

.dds-breadcrumbs {
  font: 400 14px/20px "Open Sans", sans-serif;
  display: inline-flex;
  -ms-flex-align: center;
  width: 100%;
}

.dds-breadcrumbs__link {
  display: inline-flex;
  -ms-flex-align: center;
  flex-shrink: 0;
}

.dds-breadcrumbs__link .dds-link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dds-breadcrumbs__link a {
  color: #000;
  font-size: 12px;
  font-family: "Open Sans";
}

.dds-link:visited {
  color: #012169;
}

.dds-breadcrumbs__link .divider {
  font: 400 14px/20px "Open Sans", sans-serif;
  margin: 0 8px;
  color: #53565A;
}

.ggr {
  color: #0F0;
}

body {
  color: #000;
}

body, input, textarea, select, button {
  font-family: "Open Sans", sans-serif !important;
}

body {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

a {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.white {
  color: #FFF !important;
}

.black {
  color: #000 !important;
}

.red {
  color: #F00 !important;
}

.green {
  color: #26890D;
}

.cool_gray2 {
  color: #D0D0CE;
}

.cool_gray11 {
  color: #53565A;
}

.cool_gray9 {
  color: #75787B;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

h2 {
  font-size: 16px;
  line-height: 24px;
}

h3 {
  font-size: 14px;
  line-height: 24px;
}

h4 {
  font-size: 13px;
  line-height: 20px;
}

mark,
.mark {
  background: #e0eeff;
}
mark:hover,
.mark:hover {
  background: #b7d7ff;
}

.weight-600 {
  font-weight: 600;
}
.weight-normal {
  font-weight: normal;
}

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}

.color-link {
  color: #00a3e0;
}
.color-danger {
  color: #da291c;
}
.color-warning {
  color: #f39200;
}
.color-success {
  color: #86bc25;
}
.color-black {
  color: #000;
}
.color-secondary {
  color: #97999b;
}

/*# sourceURL=typography.scss */
.heading_h1 {
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

@font-face {
  font-family: "dds-icon-sm";
  src: url("../../../assets/fonts/dds-icon-sm.eot?2ri1vx");
  src: url("../../../assets/fonts/dds-icon-sm.eot?2ri1vx#iefix") format("embedded-opentype"), url("../../../assets/fonts/dds-icon-sm.ttf?2ri1vx") format("truetype"), url("../../../assets/fonts/dds-icon-sm.woff?2ri1vx") format("woff"), url("../../../assets/fonts/dds-icon-sm.svg?2ri1vx#dds-icon-sm") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.dds-icon_sm {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dds-icon-sm" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dds-icon_search:before {
  content: "";
}

.dds-icon_sorting-Z-A:before {
  content: "";
}

.dds-icon_sorting-A-Z:before {
  content: "";
}

.dds-icon_sort-up:before {
  content: "";
}

.dds-icon_sort-down:before {
  content: "";
}

.dds-icon_download:before {
  content: "";
}

.dds-icon_upload:before {
  content: "";
}

.dds-icon_delete:before {
  content: "";
}

.dds-icon_bold:before {
  content: "";
}

.dds-icon_italic:before {
  content: "";
}

.dds-icon_underline-:before {
  content: "";
}

.dds-icon_crossed-out:before {
  content: "";
}

.dds-icon_x2:before {
  content: "";
}

.dds-icon_x2-2:before {
  content: "";
}

.dds-icon_list-bulit:before {
  content: "";
}

.dds-icon_number-list:before {
  content: "";
}

.dds-icon_truncate:before {
  content: "";
}

.dds-icon_dots-v:before {
  content: "";
}

.dds-icon_align-left:before {
  content: "";
}

.dds-icon_align-center:before {
  content: "";
}

.dds-icon_align-right:before {
  content: "";
}

.dds-icon_hyperlink:before {
  content: "";
}

.dds-icon_bookmark:before {
  content: "";
}

.dds-icon_info:before {
  content: "";
}

.dds-icon_close:before {
  content: "";
}

.dds-icon_h1:before {
  content: "";
}

.dds-icon_h2:before {
  content: "";
}

.dds-icon_h3:before {
  content: "";
}

.dds-icon_arrow-down:before {
  content: "";
}

.dds-icon_arrow-up:before {
  content: "";
}

.dds-icon_arrow-left:before {
  content: "";
}

.dds-icon_arrow-right:before {
  content: "";
}

.dds-icon_check:before {
  content: "";
}

.dds-icon_file:before {
  content: "";
}

.dds-icon_user:before {
  content: "";
}

.dds-icon_users:before {
  content: "";
}

.dds-icon_minus:before {
  content: "";
}

.dds-icon_plus:before {
  content: "";
}

.dds-icon_star-border:before {
  content: "";
}

.dds-icon_star-filled:before {
  content: "";
}

.dds-icon_list-24:before {
  content: "";
}

.dds-icon_grid-24:before {
  content: "";
}

.dds-icon_folder-24:before {
  content: "";
}

.dds-icon_edit-24:before {
  content: "";
}

.dds-icon_calendar:before {
  content: "";
}

.dds-icon_external-link:before {
  content: "";
}

.dds-icon_archive:before {
  content: "";
}

.dds-icon_copy:before {
  content: "";
}

.dds-icon_chat:before {
  content: "";
}

.dds-icon_timer:before {
  content: "";
}

.dds-icon_jpg:before {
  content: "";
}

.dds-icon_png:before {
  content: "";
}

.dds-icon_pdf:before {
  content: "";
}

.dds-icon_psd:before {
  content: "";
}

.dds-icon_doc:before {
  content: "";
}

.dds-icon_xls:before {
  content: "";
}

.dds-icon_csv:before {
  content: "";
}

.dds-icon_xml:before {
  content: "";
}

.dds-icon_zip:before {
  content: "";
}

.dds-icon_txt:before {
  content: "";
}

.dds-icon_other:before {
  content: "";
}

.dds-icon_lightbulb:before {
  content: "";
}

.dds-icon_crop:before {
  content: "";
}

.dds-icon_settings:before {
  content: "";
}

.dds-icon_sort:before {
  content: "";
}

.dds-icon_filter:before {
  content: "";
}

.dds-icon_switch:before {
  content: "";
}

.dds-icon_scissors:before {
  content: "";
}

.dds-icon_addfriend:before {
  content: "";
}

.dds-icon_arrow_v2:before {
  content: "";
}

.dds-icon_removefrom:before {
  content: "";
}

.dds-icon_eyeopen:before {
  content: "";
}

.dds-icon_eyeclose:before {
  content: "";
}

.dds-icon_clip:before {
  content: "";
}

.dds-icon_print:before {
  content: "";
}

.dds-icon_discount:before {
  content: "";
}

.dds-icon_cancel:before {
  content: "";
}

.dds-icon_credit-memo:before {
  content: "";
}

.dds-icon_accrual:before {
  content: "";
}

.dds-icon_adjustment:before {
  content: "";
}

.dds-icon_arrow-turn-right:before {
  content: "";
}

.dds-icon_arrow-round-right:before {
  content: "";
}

.dds-icon_arrow-long-up:before {
  content: "";
}

.dds-icon_arrow-long-down:before {
  content: "";
}

.dds-icon_canceled-hourglass:before {
  content: "";
}

.dds-icon_hourglass:before {
  content: "ດ";
}

.dds-icon_select-all:before {
  content: "";
}

.dds-icon_wide-view:before {
  content: "";
}

/*@font-face {
  font-family: 'dds-icon-sm';
  src: url('fonts/dds-icon-sm.eot?2ri1vx');
  src: url('fonts/dds-icon-sm.eot?2ri1vx#iefix') format('embedded-opentype'), url('fonts/dds-icon-sm.ttf?2ri1vx') format('truetype'), url('fonts/dds-icon-sm.woff?2ri1vx') format('woff'), url('fonts/dds-icon-sm.svg?2ri1vx#dds-icon-sm') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}*/
.container800 {
  width: 100%;
  margin: 0 auto;
}

.question_row {
  width: 100%;
  float: left;
  /*border-bottom: 1px solid #EEE;*/
  /*padding-bottom: 20px;*/
  margin-top: 30px;
}

.question_msg {
  height: 20px;
  width: 86px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  padding: 1px 12px 1px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background: #EEE;
}

.question_row h2 {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  font-family: Open Sans;
  margin: 0px;
}

.question_row span {
  font-weight: 700;
}

.question_row label {
  /*font-weight: 400;*/
  font-size: 12px;
  line-height: 16px;
  color: #333;
}

.question_row .container-cr {
  margin-bottom: 12px;
}

.question_row .txt_check {
  top: 0px;
}

.form_txt {
  width: 282px;
  height: 32px;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.txt_area {
  border: 1px solid #D0D0CE;
}

.bold {
  font-weight: bold !important;
}

.nomargin {
  margin: 0px !important;
}

.attestation-btn {
  float: right;
  width: 100%;
}

.attestation-btn button {
  float: right;
  margin-left: 10px;
}

.validation-msg {
  color: #d9534f;
}

.sub_questions h2 {
  padding-bottom: 15px;
}

.question_row.container800 {
  padding-top: 30px;
}

.dot h2:before {
  content: " . ";
  color: #5d5a5a;
  font-size: 30px;
  position: relative;
  top: -3px;
}

.dot h2 {
  text-indent: -25px;
}

.attestaion_row label {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #53565A;
}

.attestaion_row .form_txt {
  color: #75787B;
}

.manda {
  width: 100%;
  background: #f3f3f3;
  padding: 5px;
  float: left;
  padding-top: 10px;
}

.left_0 {
  padding-left: 0px;
}

.right_0 {
  padding-right: 0px;
}

.circle_r {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 100%;
  float: left;
  margin-right: 15px;
  position: relative;
  top: 8px;
}

.rect_c {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  float: left;
  margin-right: 15px;
  position: relative;
  top: 8px;
}

.font_12 {
  font-size: 12px !important;
}

.add_btnq {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  background: none;
  border: 0px;
  color: #007CB0;
}

.remove_ques {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  background: none;
  border: 0px;
}

.delete_ques {
  font-size: 14px !important;
  position: relative;
  top: 2px;
  margin-left: 10px;
}

.add_ques {
  background: #26890D;
  color: #FFF;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.add_ques .check_ar {
  position: relative;
  top: 2px;
}

/*Tab and Mobile Responsive styles*/
@media (min-width: 768px) {
  .container800 {
    width: 800px;
    margin: 0 auto;
    float: none;
    margin-top: 30px;
  }
}
/*spinner styles */
/*Main Spinner*/
@-webkit-keyframes uil-default-anim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes uil-default-anim {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.uil-default-css > div:nth-of-type(1) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(2) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.4722222222s;
  animation-delay: -0.4722222222s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(3) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.4444444444s;
  animation-delay: -0.4444444444s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(4) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.4166666667s;
  animation-delay: -0.4166666667s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(5) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.3888888889s;
  animation-delay: -0.3888888889s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(6) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.3611111111s;
  animation-delay: -0.3611111111s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(7) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.3333333333s;
  animation-delay: -0.3333333333s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(8) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.3055555556s;
  animation-delay: -0.3055555556s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(9) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.2777777778s;
  animation-delay: -0.2777777778s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(10) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(11) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.2222222222s;
  animation-delay: -0.2222222222s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(12) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.1944444444s;
  animation-delay: -0.1944444444s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(13) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.1666666667s;
  animation-delay: -0.1666666667s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(14) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.1388888889s;
  animation-delay: -0.1388888889s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(15) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.1111111111s;
  animation-delay: -0.1111111111s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(16) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.0833333333s;
  animation-delay: -0.0833333333s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(17) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.0555555556s;
  animation-delay: -0.0555555556s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(18) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: -0.0277777778s;
  animation-delay: -0.0277777778s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(19) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(20) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.0277777778s;
  animation-delay: 0.0277777778s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(21) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.0555555556s;
  animation-delay: 0.0555555556s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(22) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.0833333333s;
  animation-delay: 0.0833333333s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(23) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.1111111111s;
  animation-delay: 0.1111111111s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(24) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.1388888889s;
  animation-delay: 0.1388888889s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(25) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.1666666667s;
  animation-delay: 0.1666666667s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(26) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.1944444444s;
  animation-delay: 0.1944444444s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(27) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.2222222222s;
  animation-delay: 0.2222222222s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(28) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(29) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.2777777778s;
  animation-delay: 0.2777777778s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(30) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.3055555556s;
  animation-delay: 0.3055555556s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(31) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.3333333333s;
  animation-delay: 0.3333333333s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(32) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.3611111111s;
  animation-delay: 0.3611111111s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(33) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.3888888889s;
  animation-delay: 0.3888888889s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(34) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.4166666667s;
  animation-delay: 0.4166666667s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(35) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.4444444444s;
  animation-delay: 0.4444444444s;
}

.uil-default-css {
  position: relative;
  background: none;
  width: 200px;
  height: 200px;
}

.uil-default-css > div:nth-of-type(36) {
  -webkit-animation-name: uil-default-anim;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: linear;
  animation-name: uil-default-anim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  -webkit-animation-delay: 0.4722222222s;
  animation-delay: 0.4722222222s;
}

.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e7e7e7;
  opacity: 0.5;
}

.mainspinner {
  margin-top: 10%;
  margin-left: 45%;
}

.mainspinner .txtbold {
  min-width: 100px;
}

.mainspinner .col-lg-offset-6 {
  margin-left: 30%;
}

/* Start :: Inline Spinner - Styles */
#circularG {
  position: relative;
  width: 34px;
  height: 34px;
  margin: auto;
  display: inline-block;
}

.circularG {
  position: absolute;
  background-color: #86bc25;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  animation-name: bounce_circularG;
  -o-animation-name: bounce_circularG;
  -ms-animation-name: bounce_circularG;
  -webkit-animation-name: bounce_circularG;
  -moz-animation-name: bounce_circularG;
  animation-duration: 1.1s;
  -o-animation-duration: 1.1s;
  -ms-animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  -moz-animation-duration: 1.1s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#circularG_1 {
  left: 0;
  top: 14px;
  animation-delay: 0.41s;
  -o-animation-delay: 0.41s;
  -ms-animation-delay: 0.41s;
  -webkit-animation-delay: 0.41s;
  -moz-animation-delay: 0.41s;
}

#circularG_2 {
  left: 4px;
  top: 4px;
  animation-delay: 0.55s;
  -o-animation-delay: 0.55s;
  -ms-animation-delay: 0.55s;
  -webkit-animation-delay: 0.55s;
  -moz-animation-delay: 0.55s;
}

#circularG_3 {
  top: 0;
  left: 14px;
  animation-delay: 0.69s;
  -o-animation-delay: 0.69s;
  -ms-animation-delay: 0.69s;
  -webkit-animation-delay: 0.69s;
  -moz-animation-delay: 0.69s;
}

#circularG_4 {
  right: 4px;
  top: 4px;
  animation-delay: 0.83s;
  -o-animation-delay: 0.83s;
  -ms-animation-delay: 0.83s;
  -webkit-animation-delay: 0.83s;
  -moz-animation-delay: 0.83s;
}

#circularG_5 {
  right: 0;
  top: 14px;
  animation-delay: 0.97s;
  -o-animation-delay: 0.97s;
  -ms-animation-delay: 0.97s;
  -webkit-animation-delay: 0.97s;
  -moz-animation-delay: 0.97s;
}

#circularG_6 {
  right: 4px;
  bottom: 4px;
  animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}

#circularG_7 {
  left: 14px;
  bottom: 0;
  animation-delay: 1.24s;
  -o-animation-delay: 1.24s;
  -ms-animation-delay: 1.24s;
  -webkit-animation-delay: 1.24s;
  -moz-animation-delay: 1.24s;
}

#circularG_8 {
  left: 4px;
  bottom: 4px;
  animation-delay: 1.38s;
  -o-animation-delay: 1.38s;
  -ms-animation-delay: 1.38s;
  -webkit-animation-delay: 1.38s;
  -moz-animation-delay: 1.38s;
}

@keyframes bounce_circularG {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.3);
  }
}
@-o-keyframes bounce_circularG {
  0% {
    -o-transform: scale(1);
  }
  100% {
    -o-transform: scale(0.3);
  }
}
@-ms-keyframes bounce_circularG {
  0% {
    -ms-transform: scale(1);
  }
  100% {
    -ms-transform: scale(0.3);
  }
}
@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.3);
  }
}
@-moz-keyframes bounce_circularG {
  0% {
    -moz-transform: scale(1);
  }
  100% {
    -moz-transform: scale(0.3);
  }
}
/* End :: Inline Spinner - Styles */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  height: 35px;
  z-index: 2;
}

.coockiePopup {
  display: block !important;
}