body {
  background-color: #FFF;
}

.main_container {
  padding-top: 70px;
}

a {
  color: #007CB0;
}

a:focus {
  outline: none;
}

.font14-regular {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #007CB0;
}

.font14-black {
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.font12-regular {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
/* Alignments */
.right {
  float: right;
}

.left {
  float: left;
}

.align_center {
  text-align: center;
}
/*margins and Paddings*/
.margin_0 {
  margin: 0px;
}

.padding_0 {
  padding: 0px;
}

.paddinleft28 {
  padding-left: 28px !important;
}

.paddingbottom-10 {
  padding-bottom: 10px;
}

.paddingbottom-20px {
  padding-bottom: 20px;
}

.margintop-10 {
  margin-top: 10px;
}

.margintop-20 {
  margin-top: 20px;
}

.margintop-30 {
  margin-top: 30px;
}

.marginbottom-0 {
  margin-bottom: 0px;
}

.marginbottom-20 {
  margin-bottom: 20px;
}

.margin-top-bottom-checkbox {
  margin-top: 17px !important;
  margin-bottom: 0px !important;
}

.marginright15 {
  margin-right: 10px;
}
.margin_right15 {
    margin-right: 15px;
}


.paddingall10 {
  padding: 10px;
}
.padding_right-0 {
  padding-right: 0px;
}

.padding_left-0 {
  padding-left: 0px;  
}

.width_full {
  width: 100%;
}

.cpointer {
  cursor: pointer;
}
/*tables styles */
.table-responsive {
  overflow-y: hidden;
}
/*positions */
.relative_p {
  position: relative;
}

.icon_pos span {
  position: relative;
  top: 3px;
}
/*icons styles*/
.grid_list .search_ico {
  top: 5px;
}

.check_icon{
    padding:2px;
    border-radius:50%;
    color: white !important ;
    background-color:dodgerblue;
    margin-left:5px;
}

.dds-icon_search,
.dds-icon_delete,
.dds-icon_delete,
.dds-icon_eyeopen,
.dds-icon_arrow-up,
.dds-icon_sort-down,
.dds-icon_plus,
.dds-icon_calendar,
.dds-icon_timer,
.dds-icon_hourglass,
.dds-icon_check,
.dds-icon_dots-v,
.dds-icon_edit-24,
.dds-dds-icon_arrow-long-up,
.dds-icon_arrow-long-down,
.dds-icon_info,
.dds-icon_arrow-round-right {
  font-size: 16px;
}

.search_ico {
  position: absolute;
  top: 10px;
  left: 8px;
}

.calendar_ico {
  position: absolute;
  top: 5px;
  right: 5px;
}

.info_ico {
    position:relative;
    top:2px;
    border: 1px solid #000;
    border-radius: 100%;
}

.dds-icon_search,
.dds-icon_delete {
  color: #D0D0CE;
}

.dds-icon_delete,
.dds-icon_eyeopen,
.dds-icon_arrow-up,
.dds-icon_arrow-down
.dds-icon_sort-down,
.dds-icon_dots-v,
.dds-icon_edit-24,
.dds-icon_timer,
.dds-icon_check,
.dds-icon_hourglass,
.dds-icon_cancel,
.dds-icon_info
{
  color: #000;
}

.dds-icon_delete,
.dds-icon_arrow-round-right {
  cursor: pointer;
}

.dds-icon_plus {
  color: #26890D;
  position: relative;
  top: 2px;
  right: -3px;
}

.dds-icon_arrow-up,
.dds-icon_arrow-down {
  color: #000;
  float: right;
  cursor: pointer;
}

.dds-icon_calendar {
  color: #75787B;
}

.sort-down_ico {
  position: relative;
  top: 0px;
  padding-left: 3px;
}

.dds-icon_timer {
  color: #75787B;
}

.over_due {
  color: #F00;
}

.full_width {
  width: 100%;
  float: left;
}

.dashboard_list .dds-icon_check {
  color: #FFF;
  background: #26890D;
  font-size: 17px !important;
}

.dashboard_list .dds-icon_hourglass {
  background: #F00;
  color: #FFF;
  font-size: 17px !important;
}

.dashboard_list .dds-icon_timer {
  background: #ffe100;
  color: #FFF;
  font-size: 17px !important;
}

.dashboard_list .dds-icon_cancel {
  background: #ff8300;
  color: #FFF;
  font-size: 17px !important;
}

.mat-datepicker-toggle .mat-button-wrapper {
    display: none !important;
}

/*buttons styles*/
/*buttos */
.nav_btns_green {
  padding: 9px 16px;
  background-color: #26890D;
  border-radius: 0px !important;
  color: #FFF !important;
}

.green_btn {
  background: #26890D;
  border-radius: 0px;
  border: 0;
}

.addnew_btn {
  background: #FFF;
  border: 1px solid #26890D;
  color: #26890D;
}

.additem {
  color: #26890D;
  border: none;
  font-weight: 700;
  font-size: 14px;
  background: none;
  padding-left: 0px;
}



.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none !important;
  outline-offset: -2px;
}

.actions .btn-none {
  background: none !important;
  border: none !important;
}

.actions .dropdown-menu > li > a {
  padding: 7px 10px;
  text-decoration: none;
  cursor: pointer;
}

.actions .btn.active, .actions .btn:active {
  box-shadow: none;
}

.btn.active, .btn:active {
  box-shadow: none !important;
}

.item_addbtn {
  position: relative;
  right: 0;
  float: right;
}

/*sub header*/
.header_second {
}

.btn {
  border-radius: 0px;
}

.btns_download {
  padding: 5px 12px;
  border: 1px solid #000 !important;
  background: #FFF !important;
  border-radius: 0px !important;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Open Sans';
  color: #000;
}

.btns_download:hover {
  color: #000;
}

.disable_btn {
  background: #BBBCBC;
  color: #FFF;
  cursor: default;
}

.disable_btn:hover {
  color: #FFF;
}

.disable_input {
  color: #FFF;
  cursor: default;
}

.line_height28 {
  line-height: 28px !important;
}

/*form controls styles*/
.form-control {
  border-radius: 0px !important;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  box-shadow: none !important;
  border: 1px solid #D0D0CE !important;
}

label {
  //styleName: body.component.accent;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

textarea {
  resize: none !important;
}

textarea:focus {
  outline: none !important;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  color: #828282;
}

.form-control[disabled], fieldset[disabled] .form-control, .disable {
  color: #828282;
}

fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] {
  cursor: default;
}

[type="checkbox"]:disabled:not(:checked) + .checkmark,
[type="checkbox"]:disabled:checked + .checkmark {
  box-shadow: none;
  border-color: #bbb;
  background-color: #e9e9e9;
  cursor: default;
}



[type="radio"]:disabled:not(:checked) + .checkmark,
[type="radio"]:disabled:checked + .checkmark {
  box-shadow: none;
  border-color: #8a8a8a;
  background-color: #bdb8b8;
  cursor: default;
}



.mat-select-panel {
  min-width: calc(100% + 1px) !important;
}
/*fancy check box and radio buttos*/
.roles_list {
  width: 100%;
  float: left;
}

.roles_list ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.roles_list ul li {
  padding-left: 15px;
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.txt_check {
  position: relative;
  top: -6px;
}

.sub_list {
  margin-top: 15px;
  margin-bottom: 15px;
}

.controles_list {
  margin-bottom: 5px;
}
/*fancy check box */
.container-cr {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_txt {
  opacity: 0;
  margin-left: -32px;
}
/* Hide the browser's default checkbox */
.container-cr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 13px;
  width: 13px;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #000;
  cursor: pointer;
}
/* On mouse-over, add a grey background color */
.container-cr:hover input ~ .checkmark {
}
/* When the checkbox is checked, add a blue background */
.container-cr input:checked ~ .checkmark {
  background-color: #26890D;
  border: none;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container-cr input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container-cr .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* end fancy check box styles*/
/*Fancy radio button styles*/
.container-rad {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.container-rad input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.container-rad .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #000;
  border-radius: 50%;
  cursor: pointer;
}
/* On mouse-over, add a grey background color */
.container-rad:hover input ~ .checkmark {
}
/* When the radio button is checked, add a blue background */
.container-rad input:checked ~ .checkmark {
  background-color: #26890D;
  border: none;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.container-rad .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container-rad input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container-rad .checkmark:after {
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}
/*Fancy radion button end */
.roles_list .nav-pills {
  width: 100%;
  float: left;
  background: #FFFFFF;
  border: 1px solid #D0D0CE;
  box-sizing: border-box;
}

.roles_list .nav-pills > li > a {
  color: #000;
  float: left;
}

.radio_btns .form-check-input {
  margin-right: 10px;
}

.radio_btns label {
  font-weight: normal;
}
/**/
.divide-line {
  height: 8px;
  width: 1px;
  top: 10px;
  border-radius: 0px;
  position: relative;
  color: #D0D0CE;
}

.actions_drop {
  min-width: 127px !important;
  Padding: 8px 0px 8px 0px;
}

.actions .dropdown-menu > li > a:focus, .actions .dropdown-menu > li > a:hover {
  color: #000;
  background: none !important;
  outline: none !important;
}

.grid_list .dropdown-menu {
  left: auto;
  right: 0px !important;
}

/*drop down actions*/
/*Grid styles */

.pro_grid .table-responsive {
  overflow: auto;
}

.table-responsive .table > thead > tr > th {
  border-bottom: 1px solid #E5E5E5;
}

.grid_list .form-control {
  border-radius: 0px !important;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #D0D0CE !important;
  height: 24px;
  padding: 3px 12px;
  box-shadow: none !important;
}

.grid_list a {
  color: #007CB0;
  text-decoration: underline;
}

.grid_list .checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}

.d_col-1 {
  width: 63px;
}

.d_col-2 {
  width: 373px;
}

.d_col-3 {
  width: 169px;
}

.d_col-4 {
  width: 122px;
}

.d_col-5 {
  width: 161px;
}

.d_col-6 {
  width: 155px;
}

.d_col-7 {
  width: 373px;
}

.userlist_col-1 {
  width: 48px;
}

.userlist_col-2 {
  width: 214px;
}

.userlist_col-3 {
  width: 639px;
}

.userlist_col-4 {
  width: 85px;
}

.user_box .form-control {
  font-size: 14px;
  color: #000;
}

.close_btn {
  font-weight: bold;
  color: #000;
  cursor: pointer;
  padding: 10px;
  margin-top: -40px;
}

.word_s {
  word-spacing: normal;
}

.assets_grid {
  overflow-y: inherit !important;
  overflow-x: inherit !important;
}

/*mat grid */
.mat-elevation-z8 {
  box-shadow: none !important;
}

.mat-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  font-family: Open Sans;
}

.mat-table > tbody > tr:nth-of-type(odd) {
  background: #FAFAFA;
}

th.mat-header-cell {
  border-top: 0 !important;
}

td.mat-cell a {
  cursor: pointer !important;
}

tr.mat-header-row,
tr.mat-row, tr.mat-footer-row {
  height: auto !important;
}


th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 10px 8px !important;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
  border-bottom-width: unset;
  border-bottom-style: none;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
  padding-left: 8px !important;
}

.mat-sort-header-content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #53565A;
  margin-bottom: 3px;
}

.mat-cell, .mat-footer-cell {
  color: rgba(0,0,0,1);
  font-family: Open Sans;
  font-weight: normal;
  font-size: 14px;
}



th.mat-header-cell {
  font-family: Open Sans;
  font-weight: normal;
  font-size: 14px;
  color: #53565A;
}



.mat-sort-header-arrow {
  display: none !important;
}

.mat-sort-header-content {
  white-space: pre;
}

.mat-column-Invoice-Date,
.mat-column-Payroll-Period-End-Date,
.mat-column-Receipt-Date,
.mat-column-Transaction-Date,
.mat-column-Corp-Card-Billing-Date {
  min-width: 120px;
}

tr.mat-row:last-child td.mat-cell {
  border-bottom: none !important;
}

.mat-column-vendorName {
  width: 130px;
}

.mat-column-deviceType {
  width: 150px;
}

.example-chip-list {
  width: 100%;
}

.mat-chip-remove {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  margin-top: -4px;

}

.close_tag {
  background-color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
  margin-right: 0;
  width: 18px;
  right: 5px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  z-index: 6000;
  font-family: Open Sans;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
  line-height: 18px;
} 
.mat-form-field.mat-focused .mat-form-field-label {
  display: none !important;
}
.tags_list .mat-form-field-label-wrapper {
  display: none;
}

.tags_list .mat-form-field-hide-placeholder .mat-input-element::-webkit-input-placeholder {
  color: #999 !important;
  -webkit-text-fill-color: #999;
  transition: inherit;
}

.multi_list {
  width: 200px;
}

.multi_user {
  display: block;
  margin: 7px 0;
}


.multi_user1 {
  float: left;
  margin: 10px;
}


.tootltip_txt {
  white-space: nowrap;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}


/*pagination styles*/
.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
  line-height: 1em !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.mat-form-field-infix {
  box-shadow: none !important;
  border: 1px solid #D0D0CE !important;
}

.mat-select-value {
  text-align: center;
}

.mat-select-panel {
  box-shadow: none !important;
  border-radius: 0px !important;
}
/*Bread scrum */
.breadscrum_col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.breadscrum_col ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.breadscrum_col ul li {
  float: left;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #000;
}
.breadscrum_col ul li:last-child {
    line-height: 20px;
    }
.breadscrum_col .glyphicon-menu-right {
    top: 3px;
}
/*footer paganation*/
.footer_page {
  text-align: right;
}

.footer_page .pagination > li > a,
.footer_page .pagination > li > span {
  border: none !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #000;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #000;
  color: #FFF !important;
}
/* model popup styles */
.mat-dialog-container {
  box-shadow: none;
  background: none;
  right: 0;
  position: fixed;
  z-index: 100000;
  width: 500px !important;
  top: 0px;
  padding: 0px !important;
}

.mat-dialog-actions {
  display: none !important;
}

.overlay {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  top: 48px;
  /* Black #000000 */
  background: #000000;
  opacity: 0.32;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.popup_container {
  position: absolute;
  width: 100%;
  right: 0;
  overflow-y: scroll;
  background: #FFF;
  padding: 20px;
  top: 48px;
}

.popup_container h1 {
    width:333px;
    float: left;
}

    .popup_container form {
        position:relative;
        top: -20px;
        }
.popup_container .close_btn {
    margin-top: 0px;
}

.popup_bottom_btn {
  float: right;
  bottom: 20px;
  right: 20px;
}
  /*people picker drop down */
  /*.cdk-overlay-container {
    width: 350px;
    background: #FFF;*/
  /* float: right; */
  /*margin-top: 84px;
    margin-right: 59px;
    position: absolute;
    right: -6px;

  }*/
  .mat-autocomplete-panel {
    max-height: 230px !important;
  }

  .mat-option {
    height: auto !important;
    border-bottom-style: none !important;
    border: 1px solid #D0D0CE;
    padding: 10px 16px !important;
  }

  .mat-option:hover,
  .mat-option:hover .mat-option-text {
    background: #26890D !important;
    color: #FFF;
  }

  .mat-option:hover .name_txt {
    color: #FFF !important;
  }

  .mat-option-text {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }


  .mail_txt {
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .mail_txt2 {
      width: 250px;
      float: left;
      }

  /*Attestation form tab nav */
  .nav-tabs > li > a {
    color: #000;
    border: 0px solid transparent;
    border-radius: 0px;
  }

  .nav > li > a:focus,
  .nav > li > a:hover {
    background: none;
    border-radius: 0px;
    border: 0px;
  }

  .attestation-form .nav-tabs > li.active > a,
  .attestation-form .nav-tabs > li.active > a:focus,
  .attestation-form .nav-tabs > li.active > a:hover {
    color: #000;
    font-weight: 700 !important;
    font-size: 14px;
    border: 0px;
    border-bottom: 2px solid #26890D;
  }

  .testuser {
    display: none;
  }

  .sublist_row {
    margin-bottom: 10px;
  }

  .cdk-overlay-pane {
  }

  .pro_grid .nav-tabs > li > a {
    cursor: pointer;
  }

  .pro_grid .nav-tabs > li.active > a,
  .pro_grid .nav-tabs > li.active > a:focus,
  .pro_grid .nav-tabs > li.active > a:hover {
    color: #000;
    font-weight: 700 !important;
    font-size: 14px;
    border: 0px;
    border-bottom: 2px solid #26890D;
  }

  .mat-paginator-range-label {
    margin: 0 3px 0 24px !important;
  }

  .uploads_f {
    width: 100%;
    margin: 0 auto;
  }

  .uploads_f .right {
    float: left !important;
  }

  .uploads_f .align_r {
    float: right;
    width: 100%;
    text-align: left;
  }

  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 0;
  }

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    opacity: 0;
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }

  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
  }


  .fixed-header {
    position: fixed;
    width: 100%;
    top: 48px;
    background: #FFF;
    padding-top: 22px;
    z-index: 2;
    min-height: 140px;
  }

.mat_fixed_header th.mat-header-cell {
  top: 185px !important;
  padding-top: 30px !important;
  z-index: 6666 !important;
  white-space: nowrap;
}

  .mat-sort-header-content span {
    cursor: pointer !important;
  }


  .entity_row label {
    position: relative;
  }

  .entity_row select {
    border-radius: 0px;
    padding: 2px 5px;
  }

  .entity_row select:focus-visible {
    border-radius: 0px;
  }

  .mat-paginator-container {
      justify-content: left !important;
      padding-left: 0px !important;
  }
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}



.template_row label {
  text-align: right;
}
.mangecontrolpage .mat-dialog-container {
    right: 35%;
}
.mangecontrolpage .popup_container {
  height: auto;
  overflow-y: hidden;
  top: 25%;
}
.mangecontrol_row{
    text-align:left;
}

/*.manage_controls th{
    white-space: nowrap;
}*/

  /*Tab and Mobile Responsive styles*/
  @media (max-width: 768px) {
    .mat-dialog-container {
      width: 100% !important;
    }
  }


  @media (min-width: 768px) {
    .popup_container {
      width: 424px;
      height: 90%
    }

    .mat-paginator-container {
      width: 33% !important;
      justify-content: left !important;
      padding-left: 0px !important;
    }

    .mat-paginator-range-label {
      margin: 0 18px 0 18px !important;
    }

    .mat-paginator-range-actions {
      margin-top: -10px;
    }

    .uploads_f {
      width: 500px;
      margin: 0 auto;
    }

    .uploads_f .align_r {
      float: right;
      width: 100%;
      text-align: right;
    }
  }

  .center-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  @media (min-width: 1024px) {
    .testuser {
      display: block;
    }
  }

  .updown-sort {
    position: relative;
    top: 30px;
    padding-left: 7px;
    cursor: pointer;
  }

  .sort_disabled {
    cursor: none;
    color: #b1b1b1;
  }

  .uploads_r {
    width: 100%;
    margin: 0 auto;
  }

  .disable_reprocess_icon {
    background: #BBBCBC;
    color: #FFF;
    cursor: default;
    pointer-events: none !important;
  }
