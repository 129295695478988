.container800 {
    width: 100%;
    margin:0 auto;
}


.question_row {
  width: 100%;
  float: left;
  /*border-bottom: 1px solid #EEE;*/
  /*padding-bottom: 20px;*/
  margin-top: 30px;
}

.question_msg {
  height: 20px;
  width: 86px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  padding: 1px 12px 1px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  background: #EEE;
}

.question_row h2 {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  font-family: Open Sans;
  margin: 0px;
}

.question_row span {
  font-weight: 700;
}

.question_row label {
  /*font-weight: 400;*/
  font-size: 12px;
  line-height: 16px;
  color: #333;
}

.question_row .container-cr {
  margin-bottom: 12px;
}
.question_row .txt_check {
  top: 0px;
}

.form_txt {
  width: 282px;
  height: 32px;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.txt_area {
  border: 1px solid #D0D0CE;
}

.bold {
  font-weight: bold !important;
}

.nomargin {
  margin: 0px !important;
}

.attestation-btn {
  float: right;
  width: 100%;
}

.attestation-btn button {
  float: right;

  margin-left: 10px;
}
.validation-msg {
  color: #d9534f
}

.sub_questions h2 {
    padding-bottom: 15px;
}
.question_row.container800 {
    padding-top: 30px;
}

.dot h2:before {
  content: " . ";
  color: #5d5a5a;
  font-size: 30px;
  position: relative;
  top: -3px;
}
.dot h2 {
  text-indent:-25px;
}
.attestaion_row label {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #53565A;
}

.attestaion_row .form_txt {
  color: #75787B;
}

.manda {
  width: 100%;
  background: #f3f3f3; 
  padding: 5px;
  float: left;
  padding-top: 10px;
}

.left_0 {
    padding-left: 0px;
}
.right_0 {
  padding-right: 0px;
}

.circle_r {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  border-radius: 100%;
  float: left;
  margin-right: 15px;
  position: relative;
  top: 8px;
}

.rect_c {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
 
  float: left;
  margin-right: 15px;
  position: relative;
  top: 8px;
}


.font_12 {
    font-size:12px !important;
}

.add_btnq {
  //styleName: body.text.m.l [14px Regular];
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  background: none;
  border: 0px;
  color: #007CB0;
}
.remove_ques {
  //styleName: body.text.m.l [14px Regular];
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  background: none;
  border:0px;
}
.delete_ques {
  font-size: 14px !important;
  position: relative;
  top: 2px;
  margin-left: 10px;
}
.add_ques {
  background: #26890D;
  color: #FFF;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.add_ques .check_ar {
    position:relative;
    top:2px;
}

/*Tab and Mobile Responsive styles*/
@media (min-width: 768px) {

  .container800 {
    width: 800px;
    margin: 0 auto;
    float: none;
    margin-top: 30px;
  }
}
