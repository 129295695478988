@font-face {
  font-family: 'dds-icon-sm';
  src: url('../../../assets/fonts/dds-icon-sm.eot?2ri1vx');
  src: url('../../../assets/fonts/dds-icon-sm.eot?2ri1vx#iefix') format('embedded-opentype'),
      url('../../../assets/fonts/dds-icon-sm.ttf?2ri1vx') format('truetype'),
      url('../../../assets/fonts/dds-icon-sm.woff?2ri1vx') format('woff'),
      url('../../../assets/fonts/dds-icon-sm.svg?2ri1vx#dds-icon-sm') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.dds-icon_sm {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dds-icon-sm' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.dds-icon_search:before {
  content: "\e90b";
}

.dds-icon_sorting-Z-A:before {
  content: "\e901";
}

.dds-icon_sorting-A-Z:before {
  content: "\e902";
}

.dds-icon_sort-up:before {
  content: "\e90f";
}

.dds-icon_sort-down:before {
  content: "\e910";
}

.dds-icon_download:before {
  content: "\e911";
}

.dds-icon_upload:before {
  content: "\e903";
}

.dds-icon_delete:before {
  content: "\e912";
}

.dds-icon_bold:before {
  content: "\e916";
}

.dds-icon_italic:before {
  content: "\e917";
}

.dds-icon_underline-:before {
  content: "\e918";
}

.dds-icon_crossed-out:before {
  content: "\e919";
}

.dds-icon_x2:before {
  content: "\e92c";
}

.dds-icon_x2-2:before {
  content: "\e92b";
}

.dds-icon_list-bulit:before {
  content: "\e921";
}

.dds-icon_number-list:before {
  content: "\e922";
}

.dds-icon_truncate:before {
  content: "\e914";
}

.dds-icon_dots-v:before {
  content: "\e904";
}

.dds-icon_align-left:before {
  content: "\e900";
}

.dds-icon_align-center:before {
  content: "\e908";
}

.dds-icon_align-right:before {
  content: "\e913";
}

.dds-icon_hyperlink:before {
  content: "\e923";
}

.dds-icon_bookmark:before {
  content: "\e925";
}

.dds-icon_info:before {
  content: "\e91a";
}

.dds-icon_close:before {
  content: "\e92d";
}

.dds-icon_h1:before {
  content: "\e92f";
}

.dds-icon_h2:before {
  content: "\e930";
}

.dds-icon_h3:before {
  content: "\e931";
}

.dds-icon_arrow-down:before {
  content: "\e932";
}

.dds-icon_arrow-up:before {
  content: "\e935";
}

.dds-icon_arrow-left:before {
  content: "\e933";
}

.dds-icon_arrow-right:before {
  content: "\e934";
}

.dds-icon_check:before {
  content: "\e945";
}

.dds-icon_file:before {
  content: "\e937";
}

.dds-icon_user:before {
  content: "\e938";
}

.dds-icon_users:before {
  content: "\e905";
}

.dds-icon_minus:before {
  content: "\e946";
}

.dds-icon_plus:before {
  content: "\e947";
}

.dds-icon_star-border:before {
  content: "\e948";
}

.dds-icon_star-filled:before {
  content: "\e949";
}

.dds-icon_list-24:before {
  content: "\e906";
}

.dds-icon_grid-24:before {
  content: "\e907";
}

.dds-icon_folder-24:before {
  content: "\e909";
}

.dds-icon_edit-24:before {
  content: "\e90a";
}

.dds-icon_calendar:before {
  content: "\e90c";
}

.dds-icon_external-link:before {
  content: "\e90e";
}

.dds-icon_archive:before {
  content: "\e915";
}

.dds-icon_copy:before {
  content: "\e91b";
}

.dds-icon_chat:before {
  content: "\e91c";
}

.dds-icon_timer:before {
  content: "\e92a";
}

.dds-icon_jpg:before {
  content: "\e939";
}

.dds-icon_png:before {
  content: "\e93a";
}

.dds-icon_pdf:before {
  content: "\e93b";
}

.dds-icon_psd:before {
  content: "\e93c";
}

.dds-icon_doc:before {
  content: "\e93d";
}

.dds-icon_xls:before {
  content: "\e93e";
}

.dds-icon_csv:before {
  content: "\e93f";
}

.dds-icon_xml:before {
  content: "\e940";
}

.dds-icon_zip:before {
  content: "\e941";
}

.dds-icon_txt:before {
  content: "\e942";
}

.dds-icon_other:before {
  content: "\e943";
}

.dds-icon_lightbulb:before {
  content: "\e90d";
}

.dds-icon_crop:before {
  content: "\e91d";
}

.dds-icon_settings:before {
  content: "\e91e";
}

.dds-icon_sort:before {
  content: "\e91f";
}

.dds-icon_filter:before {
  content: "\e920";
}

.dds-icon_switch:before {
  content: "\e924";
}

.dds-icon_scissors:before {
  content: "\e926";
}

.dds-icon_addfriend:before {
  content: "\e927";
}

.dds-icon_arrow_v2:before {
  content: "\e928";
}

.dds-icon_removefrom:before {
  content: "\e929";
}

.dds-icon_eyeopen:before {
  content: "\e92e";
}

.dds-icon_eyeclose:before {
  content: "\e936";
}

.dds-icon_clip:before {
  content: "\e944";
}

.dds-icon_print:before {
  content: "\e94a";
}

.dds-icon_discount:before {
  content: "\e990";
}

.dds-icon_cancel:before {
  content: "\e991";
}

.dds-icon_credit-memo:before {
  content: "\e992";
}

.dds-icon_accrual:before {
  content: "\e993";
}

.dds-icon_adjustment:before {
  content: "\e994";
}

.dds-icon_arrow-turn-right:before {
  content: "\e995";
}

.dds-icon_arrow-round-right:before {
  content: "\e996";
}

.dds-icon_arrow-long-up:before {
  content: "\e997";
}

.dds-icon_arrow-long-down:before {
  content: "\e998";
}

.dds-icon_canceled-hourglass:before {
  content: "\e999";
}

.dds-icon_hourglass:before {
  content: "\e94";
}

.dds-icon_select-all:before {
  content: "\e94b";
}

.dds-icon_wide-view:before {
  content: "\e94c";
}
